<template>
  <n-modal v-bind="$props" :width="500">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col1>
        <!-- id -->
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <!-- companyId -->
        <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" :error="error.companyId" label="company" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <!-- configType -->
        <n-field-enum v-model="model.configType" field-name="configType" enum-name="CompanyConfigType" label="configType" :error="error.configType" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <!-- configValue -->
        <n-field-text v-if="model.secure" value="********" field-name="configValue" label="configValue" :error="error.configValue" :disabled="true" />
        <n-field-text v-else v-model="model.configValue" field-name="configValue" label="configValue" :error="error.configValue" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { edit, validate } from '@/api/company/company-config';

export default NModal.extend({
  name: 'ModalCompanyConfig',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return edit(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
