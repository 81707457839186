import { $fetch, $post } from '@/services/axios';

export const CSV_URL = `company/configs/csv`;

export function validate(data) {
  return $post(`company/config/validate`, data);
}

export function edit(data) {
  return $post(`company/config/${data.id}`, data);
}

export function fetch(data) {
  return $fetch(`company/${data.companyId}/configs`);
}
